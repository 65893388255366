import { Camera, ElectricBolt, Favorite, Message } from '@mui/icons-material';
import { Box, Button, FormGroup } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC, useRef, useState } from 'react';
import FormDialog from '../../../../shared/components/FormDialog';
import {
   ConsumableItemType,
   ProfileModel,
   useAdminGrantConsumableMutation,
} from '../../../../shared/utilities/__generated__/graphql';

const grantableCnosumableItemsWithIcons = {
   [ConsumableItemType.BOOST]: <ElectricBolt />,
   [ConsumableItemType.MATCH_CHAT_UNLOCK]: <Message />,
   [ConsumableItemType.PHOTO_TEST]: <Camera />,
   [ConsumableItemType.SUPER_LIKE]: <Favorite />,
};

const GrantConsumableButton: FC<{ user: ProfileModel; onChange?: () => void }> = ({ user }) => {
   const notification = useRef<SnackbarKey | undefined>(undefined);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [isOpen, setIsOpen] = useState(false);

   const [grantConsumable, { error, loading }] = useAdminGrantConsumableMutation({});

   const confirm = useConfirm();

   const handleSubmit = async (type: ConsumableItemType) => {
      closeSnackbar(notification.current);
      notification.current = enqueueSnackbar(`Granting consumable...`, {
         variant: 'info',
         autoHideDuration: null,
      });

      const result = await grantConsumable({
         variables: {
            type,
            userId: user.id!,
            expiresAt: null,
         },
      });

      if (result.data) {
         closeSnackbar(notification.current);
         enqueueSnackbar(`One instance of ${type} has been granted to ${user.name}`, {
            variant: 'success',
         });
      }
   };

   return (
      <>
         <Button startIcon={<ElectricBolt />} variant='outlined' onClick={() => setIsOpen(true)}>
            Grant consumable
         </Button>
         <FormDialog
            title='Grant user consumable'
            open={isOpen}
            onClose={() => setIsOpen(false)}
            loading={loading}
            hasActions={false}
         >
            <FormGroup>
               <Box mt={2}>
                  {Object.entries(grantableCnosumableItemsWithIcons).map(([type, icon]) => {
                     return (
                        <Button
                           key={type}
                           variant='outlined'
                           startIcon={icon}
                           onClick={() => {
                              confirm({
                                 title: `Grant ${type} to ${user.name}?`,
                                 confirmationText: 'Grant',
                                 cancellationText: 'Cancel',
                              }).then(() => handleSubmit(type as ConsumableItemType));
                           }}
                           sx={{ mt: 1, mr: 1 }}
                        >
                           {type}
                        </Button>
                     );
                  })}
               </Box>

               {error && <div>Something went wrong: {error.message}</div>}
            </FormGroup>
         </FormDialog>
      </>
   );
};

export default GrantConsumableButton;
