import { AutoGraph } from '@mui/icons-material';
import { Box, Button, CircularProgress, FormGroup, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC, useRef, useState } from 'react';
import FormDialog from '../../../../shared/components/FormDialog';
import {
   ProfileModel,
   useAdminGrantPremiumMutation,
} from '../../../../shared/utilities/__generated__/graphql';

const GrantPremiumButton: FC<{ user: ProfileModel; onChange?: () => void }> = ({ user }) => {
   const notification = useRef<SnackbarKey | undefined>(undefined);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [isOpen, setIsOpen] = useState(false);

   const [grantPremium, { error, loading }] = useAdminGrantPremiumMutation({});

   const [until, setUntil] = useState<Date | null>(null);

   const handleSubmit = async () => {
      if (!until) {
         enqueueSnackbar('Please select a date', {
            variant: 'error',
         });
         return;
      }

      closeSnackbar(notification.current);
      notification.current = enqueueSnackbar(`Granting consumable...`, {
         variant: 'info',
         autoHideDuration: null,
      });

      const result = await grantPremium({
         variables: {
            userId: user.id!,
            until: until!,
         },
      });

      const untilTime = until!.getTime() - new Date().getTime();
      const untilDays = Math.ceil(untilTime / (1000 * 60 * 60 * 24));

      closeSnackbar(notification.current);
      setIsOpen(false);
      if (result.data) {
         enqueueSnackbar(`Premium has been granted to ${user.name} for ${untilDays} days`, {
            variant: 'success',
         });
      }
   };

   return (
      <>
         <Button startIcon={<AutoGraph />} variant='outlined' onClick={() => setIsOpen(true)}>
            Grant premium
         </Button>
         <FormDialog
            title='Grant all premium entitlements'
            open={isOpen}
            onClose={() => setIsOpen(false)}
            loading={loading}
            hasActions={false}
            sx={{
               width: '1000x',
            }}
         >
            <FormGroup>
               <Box mt={2}>
                  <Typography>For how long should they get premium?</Typography>
               </Box>
               <Box mt={2}>
                  <DatePicker
                     label='Until'
                     value={until}
                     onChange={(value) => setUntil(value)}
                     renderInput={(params) => <TextField {...params} />}
                  />
               </Box>
               <Box mt={2}>
                  <Button
                     variant='contained'
                     onClick={() => handleSubmit()}
                     disabled={!until || loading}
                  >
                     Grant premium
                  </Button>
                  {loading && <CircularProgress size={20} />}
               </Box>

               {error && <div>Something went wrong: {error.message}</div>}
            </FormGroup>
         </FormDialog>
      </>
   );
};

export default GrantPremiumButton;
